exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-karir-detail-tsx": () => import("./../../../src/pages/karir/detail.tsx" /* webpackChunkName: "component---src-pages-karir-detail-tsx" */),
  "component---src-pages-karir-index-tsx": () => import("./../../../src/pages/karir/index.tsx" /* webpackChunkName: "component---src-pages-karir-index-tsx" */),
  "component---src-pages-kebijakan-privasi-tsx": () => import("./../../../src/pages/kebijakan-privasi.tsx" /* webpackChunkName: "component---src-pages-kebijakan-privasi-tsx" */),
  "component---src-pages-komunitas-category-index-tsx": () => import("./../../../src/pages/komunitas/category/index.tsx" /* webpackChunkName: "component---src-pages-komunitas-category-index-tsx" */),
  "component---src-pages-komunitas-detail-tsx": () => import("./../../../src/pages/komunitas/detail.tsx" /* webpackChunkName: "component---src-pages-komunitas-detail-tsx" */),
  "component---src-pages-komunitas-index-tsx": () => import("./../../../src/pages/komunitas/index.tsx" /* webpackChunkName: "component---src-pages-komunitas-index-tsx" */),
  "component---src-pages-kontak-tsx": () => import("./../../../src/pages/kontak.tsx" /* webpackChunkName: "component---src-pages-kontak-tsx" */),
  "component---src-pages-media-detail-tsx": () => import("./../../../src/pages/media/detail.tsx" /* webpackChunkName: "component---src-pages-media-detail-tsx" */),
  "component---src-pages-media-index-tsx": () => import("./../../../src/pages/media/index.tsx" /* webpackChunkName: "component---src-pages-media-index-tsx" */),
  "component---src-pages-mitra-detail-tsx": () => import("./../../../src/pages/mitra/detail.tsx" /* webpackChunkName: "component---src-pages-mitra-detail-tsx" */),
  "component---src-pages-mitra-index-tsx": () => import("./../../../src/pages/mitra/index.tsx" /* webpackChunkName: "component---src-pages-mitra-index-tsx" */),
  "component---src-pages-tentang-tsx": () => import("./../../../src/pages/tentang.tsx" /* webpackChunkName: "component---src-pages-tentang-tsx" */)
}

